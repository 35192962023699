.rectangle {
  height: 41px;
  display: flex;
  width: 100%;
  padding: 0px 50px;
}
.boxShadowPares {
  min-height: 45px;
  position: relative;
  border-radius: 30px;
}
.boxShadowPares label {
  position: absolute;
  top: -9px;
  left: 7px;
  z-index: 1;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  transform: scale(0.85);
  padding: 0 0.75rem;
}

.boxShadowParesChek {
  min-height: 45px;
  position: relative;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.box {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.28px;
  line-height: 16px;
  border-radius: 12px;
  padding: 5px 15px;
}

.arrow {
  display: none;
}

.check {
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  top: calc((0% - (100% - 1em)) - 8%);
  position: absolute;
}

.star > div {
  height: 45px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 15px;
}
.calendarioBox {
  height: 45px;
  border-radius: 30px;
  padding: 0 12px;
}
.calendarioBox > div {
  border: none;
}
.calendario {
  border-radius: 30px;
  overflow: hidden;
}
.label {
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 24px;
  margin-bottom: 0 !important;
}
.img {
  width: 100%;
  border-radius: 14px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}
.btn {
  text-transform: uppercase;
  align-items: center;
  margin-right: auto;
  justify-content: center;
  width: 230px;
  height: 45px;
  outline: 0;
  border: none;
  border-radius: 22.5px;
  color: #ffffff;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
  display: flex;
  padding: 0 20px;
  margin-left: 0;
}
.btnR {
  margin-right: 0;
  margin-left: auto;
}

.btn::before {
  border-radius: 22.5px;
  width: 230px;
  height: 45px;
}

.upload {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  height: 34px;
  border-radius: 17px;
  margin: auto;
  padding: 0px 15px;
}

.btnHotario {
  width: 30px;
  height: 30px;
  outline: 0;
  border: none;
  border-radius: 50%;
  margin-left: 10px;

  padding: 0;
}

@media (max-width: 767px) {
  .values {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 18px;

    text-align: center;
    width: 100%;
    display: block;
    margin-top: 8px;
    margin-bottom: -11px;
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  .boxShadowPares {
    border-radius: 22.5px;
  }
  .box {
    padding: 0px 11px;
    border-radius: 14.5px;
    min-height: 26px;
    min-width: 47px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .rectangle {
    padding: 0px 35px;
  }
}
@media (max-width: 400px) {
  .star > div {
    margin-top: 20px;
  }
}
