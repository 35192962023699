/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/Outfit/QGYvz_MVcBeNP4NJtEtq.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* @import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

body {
  margin: 0;
  font-family: Outfit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  top: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: relative;
  min-height: 100%;
  top: 0px;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.padding {
  padding: 10px;
}

.dropdown-toggle::after {
  display: none;
  margin-left: auto;
  vertical-align: auto;
  content: "";
  border-top: none;
  border-right: none;
  border-bottom: initial;
  border-left: none;
}
a:hover {
  text-decoration: none;
}
.nopadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

/* read more*/
.sidebar-box {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar-box .read-more div {
  text-decoration: none;
  border-radius: 6px;
  padding: 7px 20px;
  position: relative;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 7px;
  font-weight: 600;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar-box-dados {
  max-height: 650px;
}
.sidebar-box-caract {
  max-height: 120px;
}
.sidebar-box-obs {
  max-height: 275px;
}
.sidebar-box-equipamento {
  max-height: 464px;
}
.sidebar-box-menu {
  max-height: 250px;
}

@media (max-width: 991px) {
  .sidebar-box-equipamento-mobile {
    max-height: 280px;
  }
  .sidebar-box-equipamento {
    max-height: 100%;
  }
}

.sidebar-box-historico {
  max-height: 464px;
}
.sidebar-box-Max {
  max-height: 100%;
}
/* read more*/

.form-check-label {
  margin-bottom: 0;
  white-space: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  letter-spacing: 0.24px;
  line-height: 19px;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}

/* tabs Pesquisa */

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.nav-tabs .nav-link {
  border: none;
}

a {
  text-decoration: none;
}

.logoTranparent {
  position: absolute;
  top: 150px;
}
.logoTranparent img {
  width: 100%;
}
.grecaptcha-badge {
  display: none !important;
}

.boxShadowPares.inactive {
  border-color: #dc3545;
}
.boxShadowPares.inactive > label {
  color: #dc3545;
}
.boxShadowPares.active {
  border-color: #1fd06c;
}
.boxShadowPares.active > label {
  color: #1fd06c;
}
.boxShadowPares.active > label > span {
  color: #fff;
  background-color: #000;
  margin-left: 10px;
  border-radius: 50%;
  padding: 0px;
  font-size: 12px;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-select:disabled {
  color: #c1c1c1;
  cursor: not-allowed;
}
.form-select:disabled ~ label {
  color: #c1c1c1 !important;
}

.form-control:disabled {
  color: #c1c1c1;
  cursor: not-allowed;
}
.form-control:disabled ~ label {
  color: #c1c1c1 !important;
}

.form-floating > .form-control:disabled::placeholder,
.form-floating > .form-control-plaintext:disabled::placeholder {
  color: #c1c1c1 !important;
}

/*inicio Float label*/
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  -webkit-transform: scale(1) translateY(-0.5rem) translateX(24px);
  -ms-transform: scale(1) translateY(-0.5rem) translateX(24px);
  transform: scale(1) translateY(-0.5rem) translateX(24px);
  height: auto;
  padding: 0 10px;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 0.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 0.625rem;
  border-color: #000;
  box-shadow: none;
}

.form-floating > label {
  opacity: 1;
  -webkit-transform: scale(1) translateY(-0.5rem) translateX(24px);
  -ms-transform: scale(1) translateY(-0.5rem) translateX(24px);
  transform: scale(1) translateY(-0.5rem) translateX(24px);
  height: auto;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 17px;
  width: auto;
  display: flex;
  align-items: center;
}

.form-floating > label img {
  margin-right: 10px;
}

.form-select:focus {
  border-color: #000;
  outline: 0;
  box-shadow: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545 !important;
}
.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #dc3545;
}

.form-floating > .form-control.is-invalid + div + label,
.form-floating > .form-select.is-invalid + div + label {
  color: #dc3545;
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  box-shadow: none;
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #1fd06c !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #1fd06c !important;
  background-image: none;
}
.form-floating > .form-control.is-valid + div + label,
.form-floating > .form-select.is-valid + div + label {
  color: #1fd06c;
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  box-shadow: none;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: 54px;
  font-size: 14px;
  letter-spacing: 0.3px;
}
.form-floating > .form-control {
  padding: 0.25rem 1rem;
}
.form-floating > .form-select {
  padding: 0.375rem 2.25rem 0.375rem 1rem;
}

/*fim Float label*/

/*inicio pretty*/
.pretty.p-icon label:before,
.pretty.p-icon label:after,
.pretty.p-icon .icon,
.pretty.p-icon .svg,
.pretty.p-icon .img {
  top: calc(0% - (100% - 67px) - 35%) !important;
}
.pretty {
  line-height: 41px;
  width: 100%;
  height: 100%;
  margin: 0;
}
.pretty .state label {
  font-size: 15px;
  letter-spacing: 0.24px;
}
/*fim pretty*/
/*inicio menu bottom*/
.dropdown-menu {
  border-radius: 0 0 22px 22px;
  padding-top: 0;
  border: none;
  top: calc(100% + 0px) !important;
  left: -40px !important;
}

.navbar .dropup .dropdown-toggle::after {
  display: none;
}

.navbar .dropup > #basic-nav-dropdown4 + .dropdown-menu {
  top: auto !important;
  left: 30px !important;
  border-radius: 22px 22px 0 0;
  bottom: 81px;
}
.navbar .dropup > #basic-nav-dropdown3 + .dropdown-menu {
  top: auto !important;
  left: -50px !important;
  border-radius: 22px 22px 0 0;
  bottom: 81px;
}
/*fim menu bottom*/

.modal-body,
.modal-header {
  padding: 1.5rem;
}

@media (max-width: 767px) {
  .modal-body,
  .modal-header {
    padding: 1rem;
  }
}

/*scrollbar*/
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  width: 10px;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
/*scrollbar*/

.offcanvas-body {
  overflow-y: overlay;
}

.div-manutencao {
  position: relative;
}

/*ficha viatura*/
.box-recomendados {
  padding: 33.35% 0;
}

.fullscreen .box-recomendados {
  padding: 23.35% 0;
}

@media (max-width: 1400px) {
  .fullscreen .box-recomendados {
    padding: 33.35% 0;
  }
}
@media (max-width: 574px) {
  .fullscreen .box-recomendados {
    padding: 41.35% 0;
  }
}
/*tradutor*/
.goog-te-combo:not(:first-child) {
  display: none;
  height: 0;
  overflow: hidden;
}
.goog-te-gadget:not(:first-child) {
  display: none;
  height: 0;
  overflow: hidden;
}
.goog-te-banner-frame {
  display: none;
  height: 0 !important;
  overflow: hidden;
}

/*tradutor*/

/*select pesquisa*/

.rfs-select-container {
  margin-left: -2px;
  margin-top: -2px;
  margin-bottom: -2px;
  width: calc(100% + 4px);
}

/*fim select pesquisa*/

@media (max-width: 576px) {
  .cookies div:first-child {
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
    flex-basis: calc(100% - 91px) !important;
    margin: 0 !important;
    padding: 15px;
  }
  .cookies div:first-child {
    align-items: flex-end !important;
  }
}

/* Cores*/
.sidebar-box {
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar-box .read-more div {
  text-decoration: none;
  border-radius: 6px;
  padding: 7px 20px;
  position: relative;
  display: inline-block;
  font-size: 13px;
  margin-bottom: 7px;
  font-weight: 600;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.sidebar-box-cores {
  max-height: 110px;
}

@media (max-width: 767px) {
  .sidebar-box-cores {
    max-height: 187px;
  }
}

@media (max-width: 574px) {
  .sidebar-box-cores {
    max-height: 225px;
  }
}

@media (max-width: 376px) {
  .sidebar-box-cores {
    max-height: 230px;
  }
}

.sidebar-box-Max {
  max-height: 100%;
}

/* Cores*/

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
}
